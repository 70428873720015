import { PageProps, graphql } from "gatsby";
import React, { useEffect } from "react";
import styled from "styled-components";

import LayOut from "@components/layout";
import SEO from "@components/shared/seo";
import Image from "@components/shared/sub/image";
import { Query } from "@graphql-types";
import { icons } from "../../static/icons/icons";
import BlocksContent from "../components/blocksContent";

const Wrapper = styled.div`
  width: 100%;
  margin: 25px auto;
  color: #505050;

  h2 {
    color: #505050;
  }

  h3 {
    color: #505050;
  }
  h5 {
    font-size: 16px;
  }
  p {
    line-height: 20px;
    margin: 0px 0px 10px 0px;
    font-size: 14px;
  }

  ol {
    margin-left: 35px;
  }

  li {
    list-style-type: decimal;
    margin-bottom: 8px;
  }

`;

const TopSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1500px;
  margin: 0 auto;
`;

const LeftContainer = styled.div`
  display: block;
  width: 25%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    display: block;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 10%;
  background-color: #f2f2f2;
`;

const DetailsWrapper = styled.div`
  padding: 30px;
  padding-left: 0px;
  @media only screen and (max-width: 1050px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 600px) {
    padding: 0px;
  }
`;

const DetailsHeader = styled.h5`
  margin-top: 0px;
  font-size: 24px;
  font-family: "header";
  @media only screen and (max-width: 600px) {
    margin-top: 30px;
  }
`;

const WebLink = styled.a`
  text-decoration: none;
  color: black;
`;

const Icon = styled.img`
  margin-right: 15px;
  width: 30px;
`;

const StyledP = styled.p`
  font-size: 18px;
  margin: 20px 0px 0px 0px !important;
  width: max-content;
`;

const RightContainer = styled.div`
  margin: 0 auto;
  width: 75%;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 0px;

  a {
    display: inline-block;
  }

  @media only screen and (max-width: 1050px) {
    padding: 50px 0px 0px 0px;
  }
`;

export const query = graphql`
  query partnerQuery($slug: String) {
    allSanityPartners(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          contactTitle
          website
          phone
          email
          excerpt
          slug {
            current
          }
          featureImage {
            ...sanityImageWithMeta
          }
          content {
            _rawColumnContent
          }
          seo {
            ...sanitySeo
          }
        }
      }
    }
  }
`;

interface PartnersInnerProps extends PageProps<Query, { slug: string }> {}

function PartnersInner({ data, pageContext }: PartnersInnerProps) {
  const {
    allSanityPartners: {
      edges: [{ node: partner }],
    },
  } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayOut>
      <SEO
        overwriteTitle={partner.title}
        overwriteDescription={partner.excerpt}
        seoData={partner.seo}
        slug={pageContext.slug}
      />

      <Wrapper>
        <TopSection>
          <LeftContainer>
            <ImageWrapper>
              {partner.featureImage && <Image data={partner.featureImage} />}
            </ImageWrapper>
            <DetailsWrapper>
              <DetailsHeader>Learn More</DetailsHeader>
              {partner.website && (
                <WebLink href={partner.website}>
                  <StyledP>
                    <Icon
                      src={icons.website}
                      alt="Website Icon"
                      width={30}
                      height={30}
                    />
                    {partner.website}
                  </StyledP>
                </WebLink>
              )}
              {partner.phone && (
                <StyledP>
                  <Icon
                    src={icons.phone}
                    alt="Phone Icon"
                    width={30}
                    height={30}
                  />
                  {partner.phone}
                </StyledP>
              )}
              {partner.email && (
                <StyledP>
                  <Icon
                    src={icons.email}
                    alt="Email Icon"
                    width={30}
                    height={30}
                  />
                  {partner.email}
                </StyledP>
              )}
            </DetailsWrapper>
          </LeftContainer>
          <RightContainer>
            <TextWrapper>
              {partner.content && (
                <BlocksContent blocks={partner.content._rawColumnContent} />
              )}
            </TextWrapper>
          </RightContainer>
        </TopSection>
      </Wrapper>
    </LayOut>
  );
}

export default PartnersInner;
